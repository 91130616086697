import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './work-entry.module.css'

class WorkEntryTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulWorkEntry')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={post.description.childMarkdownRemark.excerpt}
          image={`http:${post.previewImage.resize.src}`}
        />

        <div className={styles.container}>
          <div className={styles.article}>
            <h1>{post.title}</h1>
            <p>{post.description?.childMarkdownRemark?.excerpt}</p>
            <div className={styles.mockupsWrapper}>
              {post.mockups.map((singleImage) => {
                return (
                  <GatsbyImage
                    className={styles.mockup}
                    alt={post.mockups.title}
                    image={singleImage.gatsbyImageData}
                  />
                )
              })}
            </div>
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/work/${previous.slug}`} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/work/${next.slug}`} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default WorkEntryTemplate

export const pageQuery = graphql`
  query WorkEntryBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulWorkEntry(slug: { eq: $slug }) {
      slug
      title
      rawDate: publishDate
      previewImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      mockups {
        title
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulWorkEntry(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulWorkEntry(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
